body {
	margin: 0;
	font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
}

.serif {
	font-family: "Merriweather", "Times New Roman", serif;
}

html,
body,
#root {
	min-height: 100vh;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
